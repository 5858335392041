import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ShippingProduct {
  id: number;
  product_name: string;
  product_id: number;
  shipping_order_id: number;
  status: string;
  shipping_order_status: string;
  purchased: boolean;
  purchase_price: number;
  created_at: string;
  updated_at: string;
  note: string;
  shipping_order: {
    name: string;
    mobile: string;
    address_detail: string;
    shipping_no: string | null;
  };
}

export interface ShippingProductInfo {
  shipping_products: Array<ShippingProduct>;
  shipping_product_count: number;
}

@Module
export default class ShippingProductModule extends VuexModule implements ShippingProductInfo {
  shipping_products = [] as Array<ShippingProduct>;
  shipping_product_count = 0;
  loading = false;

  get currentShippingProducts(): Array<ShippingProduct> {
    return this.shipping_products;
  }

  get currentShippingProductCount(): number {
    return this.shipping_product_count;
  }

  @Mutation
  [Mutations.SET_SHIPPING_PRODUCTS](data: any) {
    this.shipping_products = data.data.items;
    this.shipping_product_count = data.data.total;
  }

  @Mutation
  [Mutations.SET_SHIPPING_PRODUCTS_LOADING](loading: boolean) {
    this.loading = loading;
  }

  @Action
  [Actions.FETCH_SHIPPING_PRODUCTS](params: any) {
    ApiService.setHeader();
    this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, true);
    
    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/shipping_orders/product_details", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SHIPPING_PRODUCTS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        })
        .finally(() => {
          this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, false);
        });
    });
  }

  @Action
  [Actions.UPDATE_SHIPPING_PRODUCT](payload: any) {
    ApiService.setHeader();
    this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, true);
    
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/shipping_orders/product_details/${payload.id}`, {
        shipping_product: {
          purchased: payload.purchased,
          purchase_price: payload.purchase_price,
          name: payload.name,
          mobile: payload.mobile,
          area: payload.area,
          address_detail: payload.address_detail,
          note: payload.note
        }
      })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        })
        .finally(() => {
          this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, false);
        });
    });
  }

  @Action
  [Actions.BATCH_DELIVER_SHIPPING_PRODUCTS](payload: any) {
    ApiService.setHeader();
    this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, true);
    
    return new Promise((resolve, reject) => {
      ApiService.post("admin/shipping_orders/batch_deliver", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, false);
        });
    });
  }

  @Action
  [Actions.CANCEL_SHIPPING_PRODUCT](id: number) {
    ApiService.setHeader();
    this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, true);
    
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/shipping_orders/details/${id}/cancel`, {})
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        })
        .finally(() => {
          this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, false);
        });
    });
  }

  @Action
  [Actions.DOWNLOAD_SHIPPING_PRODUCTS](payload: any): Promise<any> {
    this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, true);

    console.log(payload)
    
    let queryString = "?";
    if (payload) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] !== null && payload[key] !== undefined) {
          queryString += `${key}=${payload[key]}&`;
        }
      });
    }

    console.log(queryString)

    return new Promise((resolve, reject) => {
      ApiService.get("admin/shipping_orders/download_excel", queryString, {
        responseType: 'blob'
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          this.context.commit(Mutations.SET_SHIPPING_PRODUCTS_LOADING, false);
        });
    });
  }
} 