import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export interface ShippingRecord {
  id: number;
  name: string;
  mobile: string;
  area: string;
  address_detail: string;
  shipping_provider: string;
  shipping_no: string;
  created_at: string;
  created_by: string;
  user_nickname: string | null;
  user_mobile: string | null;
  products: Array<{
    id: number;
    name: string;
    purchase_price: number;
    quantity: number;
  }>;
}

export interface ShippingRecordInfo {
  shipping_records: Array<ShippingRecord>;
  shipping_record_count: number;
}

@Module
export default class ShippingRecordModule extends VuexModule implements ShippingRecordInfo {
  shipping_records = [] as Array<ShippingRecord>;
  shipping_record_count = 0;
  loading = false;

  get currentShippingRecords(): Array<ShippingRecord> {
    return this.shipping_records;
  }

  get currentShippingRecordCount(): number {
    return this.shipping_record_count;
  }

  @Mutation
  [Mutations.SET_SHIPPING_RECORDS](data: any) {
    this.shipping_records = data.data.list;
    this.shipping_record_count = data.data.total;
  }

  @Mutation
  [Mutations.SET_SHIPPING_RECORDS_LOADING](loading: boolean) {
    this.loading = loading;
  }

  @Action
  [Actions.FETCH_SHIPPING_RECORDS](params: any) {
    ApiService.setHeader();
    this.context.commit(Mutations.SET_SHIPPING_RECORDS_LOADING, true);
    
    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/shipping_orders/shipping_records", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SHIPPING_RECORDS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        })
        .finally(() => {
          this.context.commit(Mutations.SET_SHIPPING_RECORDS_LOADING, false);
        });
    });
  }

  @Action
  [Actions.UPDATE_SHIPPING_RECORD](payload: { id: number; shipping_provider: string; shipping_no: string }) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/shipping_orders/shipping_records/${payload.id}/update`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DOWNLOAD_SHIPPING_RECORDS](payload: any): Promise<any> {
    this.context.commit(Mutations.SET_SHIPPING_RECORDS_LOADING, true);
    
    let queryString = "?";
    if (payload) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] !== null && payload[key] !== undefined) {
          queryString += `${key}=${payload[key]}&`;
        }
      });
    }

    return new Promise((resolve, reject) => {
      ApiService.get("admin/shipping_orders/download_shipping_records_excel", queryString, {
        responseType: 'blob'
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          this.context.commit(Mutations.SET_SHIPPING_RECORDS_LOADING, false);
        });
    });
  }
}
